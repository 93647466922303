/** INPUT **/
.yan-input {
  color: var(--black);
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  padding: 0 12px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  line-height: 45px;
  width: 100%;
  transition: border-color 0.5s ease;

  &[type='number'] {
    font-family: sans-serif;
  }

  &.search {
    padding-left: 45px;
    background: url("/assets/img/search.svg") 15px 50% no-repeat #fff;
    background-size: 20px 20px;
  }

  &:focus {
    border-color: var(--primary);
    box-shadow: 0 0 2px var(--primary);
  }

  &.is-invalid {
    border-color: var(--red) !important;

    &:focus {
      box-shadow: 0 0 2px var(--red) !important;
    }
  }

  &:disabled {
    background: var(--bgd-gray);
  }

}

textarea.yan-input {
  height: 80px;
  line-height: 25px;
  padding: 12px;
  font-weight: 400 !important;
}


/** BUTTON **/
.yan-btn {
  display: block;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 0 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  line-height: 45px;
  background: var(--primary); // rgba(27, 87, 120, 255);
  font-size: 13px;
  transition: all 200ms ease;
  border-radius: 8px;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    background: var(--primary-dark);
    color: #fff;
  }

  &:active {
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  }

  &.secondary {
    background: var(--black);
  }

  &.reverse {
    transition: border-color 500ms ease, color 500ms ease, background 500ms ease;
    border-color: var(--primary);
    background: #fff;
    color: var(--primary);
  }

  &.reverse:hover {
    background: var(--primary);
    color: #fff;
  }

  &.white-btn,
  &.reset {
    border-color: var(--brdr-gray);
    background: #fff;
    color: var(--black);
  }

  &.white-btn:hover {
    border-color: var(--primary);
  }

  &.reset:hover {
    color: #fff;
    background: var(--orange);
  }

  &:not(.right-ic) span {
    margin-left: 10px;
  }

  &.right-ic span {
    margin-right: 10px;
  }

  &[disabled] {
    background: var(--gray) !important;
    cursor: inherit;
    box-shadow: unset;
  }
}

#app-direction[dir="rtl"] .yan-btn {
  font-size: 15px;

  &:not(.right-ic) span {
    margin-left: unset;
    margin-right: 10px;
  }

  &.right-ic span {
    margin-right: unset;
    margin-left: 10px;
  }
}

/** BTNS **/
.yan-btns {
  margin-top: 25px;
  display: flex;
  gap: 5px;

  .yan-btn {
    min-width: 120px;
    line-height: 40px;
  }
}


/** FORM FIELD **/
.yan-field {
  margin-bottom: 25px;
  position: relative;

  &:last-child {
    margin-bottom: 0 !important;
  }

  .yan-field-label {
    margin-bottom: 7px;

    label.yan-label {
      display: flex;
      align-items: center;
      font-size: 13px;

      span {
        display: block;

        &.mandatory {
          color: var(--primary);
          margin: 0 3px;
        }

      }
    }

    .yan-sub-label {
      display: block;
      font-size: 12px;
      color: var(--gray);
    }
  }

  .yan-field-content {
    display: block;
    position: relative;
  }

  .yan-field-desc {
    margin-top: 5px;
    font-size: 12px;
    color: var(--gray);
  }

}

#app-direction[dir="rtl"] {
  .yan-field {
    label.yan-label {
      font-size: 14px;
    }
  }
}

.mat-form-field {
  width: 100%;
  display: block;
  background: #fff;

  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0;

    .mat-form-field-infix {
      border-top: unset;
      padding: 0;
      line-height: 50px;
    }

    .mat-form-field-suffix {
      top: 0.4em;
    }

    .mat-select-arrow-wrapper {
      transform: unset;
    }

    .mat-select-value,
    input.mat-input-element {
      color: var(--black);
      font-size: 14px;
    }

    .mat-form-field-outline-thick {
      & > div {
        border-width: 1px !important;
        color: var(--primary);
      }
    }
  }
}

.mat-form-field-invalid .mat-form-field-outline-thick > div {
  color: var(--red) !important;
}

/*** MAIN ***/
.yan-main {
  margin: 0 auto;
  max-width: 1225px;
  padding: 30px 20px;
  overflow: hidden;
  position: relative;

  &.bottom-p {
    padding-bottom: 100px;
  }
}

/** FLEX **/
.yan-flex {
  display: flex !important;
  gap: 10px;

  & > * {
    display: block;
    width: 100%;
  }

  .mat-form-field {
    margin-top: -3px;
  }
}

/* ### yan TITLE ###*/
.yan-title {
  font-weight: 450;
  font-size: 17px;
  margin-bottom: 30px;
  color: #000;

  @media (max-width: 700px) {
    font-size: 22px !important;
    margin-bottom: 15px !important;
  }
}

.yan-alert {
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 20px;
  position: relative;
  gap: 15px;
  align-items: center;
  display: flex;

  fa-icon {
    font-size: 8px;
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
    margin-bottom: 15px;
  }

  &.SUCCESS {
    color: rgb(22 101 52);
    background-color: rgb(240 253 244);
  }

  &.ERROR {
    color: rgb(153 27 27);
    background: rgb(254 242 242);
  }

  &.WARNING {
    color: rgb(133 77 14);
    background-color: rgb(254 252 232);
  }

  &.INFO {
    color: rgb(29 78 216);
    background-color: rgb(239 246 255);
  }

  &.animate {
    animation: shake 1s cubic-bezier(.36, .07, .19, .97) both infinite;
  }

  span {
    font-weight: 450;
  }
}

/* ### YAN PAGE ### */
.yan-page {
  position: relative;
  min-height: 200px;

  &.yan-form {
    max-width: 1200px;
  }

  .yan-page-header {
    margin-bottom: 20px;
    position: relative;

    .yph-title {
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;

      .back {
        height: 30px;
        width: 45px;
        border-radius: 30px;
        cursor: pointer;
        text-align: center;
        line-height: 29px;
        font-size: 13px;
        background: #fff;
        border: 1px solid var(--brdr-gray);

        &:hover {
          color: var(--primary);
          border-color: var(--primary);
        }
      }

      .yan-title {
        margin-bottom: 0;
      }
    }

    .yan-page-actions {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 7px;

      .action {
        flex-shrink: 0;

        &.yan-btn {
          height: 35px;
          line-height: 33px;
        }

        &:first-child {
          margin-left: 0;
        }

        .right-actions {
          margin-left: auto;
        }
      }
    }
  }

  .yan-page-content {
    min-height: 500px;
    position: relative;
  }

  .ypc-header {

    & > .yan-tabs {
      margin-bottom: 20px;
    }

    .fp {
      display: flex;
      align-items: center;
      gap: 7px;

      .search-co {
        display: flex;
        align-items: center;
        flex-grow: 1;
        gap: 7px;

        .per-page {
          cursor: pointer;
          border-radius: 8px;
          border: 1px solid var(--brdr-gray);
          flex-shrink: 0;
          padding: 0 15px;
          line-height: 40px;
          font-size: 13px;
          background: #fff;

          span {
            margin-right: 7px;
          }

          &:hover {
            color: var(--primary);
          }
        }

        .search {
          line-height: 40px;
          min-width: 200px;
          background-size: 18px 18px;
        }

        .filters {
          display: flex;
          overflow: hidden;
          border-radius: 8px;
          border: 1px solid var(--brdr-gray);
          flex-shrink: 0;

          & > div {
            cursor: pointer;
            padding: 0 15px;
            line-height: 40px;
            border-right: 1px solid var(--brdr-gray);
            font-size: 13px;

            span {
              margin-right: 7px;
            }

            &:hover {
              color: var(--primary);
            }

            &:last-child {
              border-right: 0;
            }
          }
        }

        .sort-by {
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid var(--brdr-gray);
          padding: 0 15px;
          line-height: 40px;
          font-size: 14px;
          cursor: pointer;

          span {
            margin-right: 10px;
          }

          &:hover {
            color: var(--primary);
          }
        }
      }

      .more-actions {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 7px;
        flex-shrink: 0;

        .grouped-actions {
          display: flex;
          overflow: hidden;
          border-radius: 3px;
          border: 1px solid var(--brdr-gray);

          & > div {
            padding: 0 15px;
            line-height: 40px;
            border-right: 1px solid var(--brdr-gray);
            font-size: 14px;
            color: var(--gray-dark);
            font-weight: 450;

            &.selected {
              fa-icon {
                color: var(--red);
              }

              span {
                margin-left: 7px;
              }
            }

            &.apply-actions span {
              margin-right: 7px;
            }

            &:last-child {
              border-right: 0;
              cursor: pointer;

              &:hover {
                color: var(--secondary);
              }
            }
          }
        }
      }
    }

    .selected-filters {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 3px;

      & > div {
        padding: 0 12px;
        font-size: 13px;
        border-radius: 3px;
        line-height: 28px;
        background: var(--bgd-gray);
        color: var(--gray-dark);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        span.del {
          cursor: pointer;
        }

        &:hover span.del {
          color: var(--red);
        }
      }
    }
  }

  .ypc-body {
    margin-top: 15px;
    position: relative;
  }

  .yan-form-section {
    margin-bottom: 20px;
    background: #fff;
    position: relative;
    border: 1px solid var(--brdr-gray);
    border-radius: 10px;
    overflow: hidden;

    .yfs-title {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 15px;
      border-radius: 10px 10px 0 0;
      background: var(--bgd-gray);

      .ttl {
        padding-left: 15px;
        position: relative;
        font-weight: 450;
        flex-grow: 1;

        &:after {
          position: absolute;
          content: '';
          display: block;
          height: 16px;
          width: 4px;
          background: var(--primary);
          top: 50%;
          margin-top: -8px;
          left: 0;
          border-radius: 3px;
        }
      }

      .back {
        height: 30px;
        width: 40px;
        margin-right: 15px;
        border-radius: 30px;
        cursor: pointer;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        &:hover {
          color: var(--primary);
        }
      }

      .slider, .actions, .arrow {
        margin-left: auto;
        flex-shrink: 0;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 5px;

        .yan-btn {
          line-height: 35px;
        }
      }

      .arrow {
        height: 20px;
        width: 20px;
        text-align: center;
        background: url('/assets/img/right-arrow_.svg') center no-repeat;
        background-size: 12px 12px;
        transition: transform .2s;
        flex-shrink: 0;
        cursor: pointer;
      }

      &:hover .arrow {
        background: url('/assets/img/right-arrow-red.svg') center no-repeat;
        background-size: 12px 12px;
      }

    }

    &.open {
      .yfs-title {
        border-bottom: 1px solid var(--brdr-gray);
      }

      .arrow {
        background: url('/assets/img/right-arrow-red.svg') center no-repeat;
        background-size: 12px 12px;
        transform: rotate(90deg);
      }
    }

    .yfs-content {
      padding: 20px;
      position: relative;
    }
  }

  .yan-form-btns {
    background: #fff;
    width: 100%;
    padding: 15px 0;
    display: flex;
    bottom: 0;
    left: 0;
    position: sticky;
    z-index: 10;

    .yan-btn {
      min-width: 120px;
      line-height: 35px;
    }

    .submit {
      margin-right: 10px;
    }

  }
}

#app-direction[dir="rtl"] {
  .yan-page {

    .ypc-header {

      .fp {
        .search-co {
          .per-page {
            span {
              margin-right: unset;
              margin-left: 7px;
            }
          }

          .filters {
            & > div {
              border-left: 1px solid var(--brdr-gray);
              border-right: unset;

              span {
                margin-right: unset;
                margin-left: 7px;
              }

              &:last-child {
                border-left: 0;
              }
            }
          }

          .sort-by {
            span {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }

        .more-actions {
          margin-right: auto;
          margin-left: unset;

          .grouped-actions {

            & > div {
              border-left: 1px solid var(--brdr-gray);
              border-right: unset;

              &.selected {
                span {
                  margin-right: 7px;
                  margin-left: unset;
                }
              }

              &.apply-actions span {
                margin-right: unset;
                margin-left: 7px;
              }

              &:last-child {
                border-left: 0;
              }
            }
          }
        }
      }

      .selected-filters {
        & > div {
          span.lbl {
            margin-right: unset;
            margin-left: 10px;
          }
        }
      }
    }

    .back {
      transform: rotate(180deg);
    }

    .yan-form-section {

      .yfs-title {
        .ttl {
          padding-right: 15px;
          padding-left: unset;
          font-size: 15px;

          &:after {
            left: unset;
            right: 0;
          }
        }

        .back {
          margin-left: 15px;
          margin-right: unset;
        }

        .slider, .actions, .arrow {
          margin-right: auto;
          margin-left: unset;
        }

        .arrow {
          transform: rotate(180deg);
        }
      }

      &.open {
        .arrow {
          transform: rotate(90deg);
        }
      }
    }

    .yan-form-btns {
      left: unset;
      right: 0;

      .submit {
        margin-left: 10px;
        margin-right: unset;
      }

    }
  }
}


/*** YAN TABLE **/
.yan-table-container {
  overflow: auto;
  padding: 15px 15px 10px;
  background: var(--bgd-gray);
  border-radius: 7px;
}

table.yan-table {
  width: 100%;

  th.mat-header-cell:last-of-type {
    text-align: center;
  }

  tr.mat-header-row {
    background: #fff;
    height: 60px;
    border-bottom: 3px solid var(--primary);

    th {
      border-bottom: none;
      text-transform: uppercase;
      font-size: 11px;
      padding: 0 10px;
      color: #333;

      &.th-center {
        text-align: center !important;
      }
    }
  }

  tr.empty-row {
    background: repeating-linear-gradient(50deg, #fff, #fff 10px, #f1f5f9 0, #f1f5f9 20px) !important;
  }

  tr.default-row {
    background: #cdf6ec !important;
  }

  tr.blur-row {
    position: relative;

    td {
      position: relative;
      filter: blur(3px);
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
      }
    }
  }

  tr.mat-row {
    border-bottom: 5px solid var(--bgd-gray);
    transition: background-color 0.5s ease-in;

    td.mat-cell {
      border-bottom-color: var(--brdr-gray);
    }

    td {
      font-size: 14px;
      min-width: 120px;
      padding: 10px;

      .center-column {
        display: flex;
        justify-content: center;
      }

      .bold-column {
        font-weight: 500;
      }
    }

    .row-actions {
      display: flex;
      justify-content: center;
      cursor: pointer;
      padding: 0 5px;
      gap: 3px;


      & > div {
        height: 34px;
        width: 34px;
        cursor: pointer;
        display: block;
        border: 1px solid var(--brdr-gray);
        transition: all 0.2s ease-in;
        background-color: #fff !important;
        border-radius: 11px;


        &:hover {
          border-color: var(--primary);
        }

        &.more {
          background: url('/assets/img/page/more.svg') center no-repeat;
          background-size: 18px 18px;
        }

        &.view {
          background: url('/assets/img/page/view.svg') center no-repeat;
          background-size: 20px 20px;
        }

        &.edit {
          background: url('/assets/img/page/edit.svg') center no-repeat;
          background-size: 14px 14px;
        }

        &.duplicate {
          background: url('/assets/img/page/duplicate.svg') center no-repeat;
          background-size: 16px 16px;
        }

        &.download {
          background: url('/assets/img/page/download.svg') center no-repeat;
          background-size: 20px 20px;
        }

        &.delete {
          background: url('/assets/img/page/delete.svg') #fff center no-repeat;
          background-size: 17px 17px;
        }

        &.refresh {
          background: url('/assets/img/page/refresh.svg') #fff center no-repeat;
          background-size: 18px 18px;
        }

        &.default {
          background: url('/assets/img/page/check.svg') #fff center no-repeat;
          background-size: 17px 17px;
        }

        &.ai {
          background: url('/assets/img/openai.svg') #fff center no-repeat;
          background-size: 17px 17px;
        }
      }
    }
  }

  .check-cell {
    width: 60px;
  }

  .mat-checkbox-frame {
    border-width: 1px;
  }

  .mat-divider {
    margin: 8px 0; /* Adjust the space between the rows */
    background-color: rgba(0, 0, 0, 0.12); /* Color of the divider */
    height: 1px; /* Thickness of the divider */
  }
}


#app-direction[dir="rtl"] {
  table.yan-table {

    tr.mat-header-row {
      th {
        &:first-child {
          border-radius: 0 4px 4px 0;
        }

        &:last-child {
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }
}

/** YAN PAGINATION */
.yan-pagination {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-nav {
    .pagination {
      gap: 5px;

      li {

        a {
          border-radius: 8px !important;
          line-height: 34px;
          padding: 0 16px;
          text-align: center;
          color: var(--primary);
          font-weight: 450;
          font-size: 13px;
          height: 35px;
        }

        &.active a {
          background-color: var(--primary);
          border-color: var(--primary);
          color: #fff;
        }

        &.disabled a {
          color: var(--gray);
        }

        &:first-child,
        &:last-child {
          a {
            font-size: 18px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

/*** YAN TABS **/
.yan-tabs {
  display: flex;
  position: relative;
  gap: 7px;

  .yan-tab-item {
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: block;
    line-height: 45px;
    padding-right: 20px;
    padding-left: 10px;
    position: relative;
    text-decoration: none;
    transition: color .2s, font-family .2s;
    font-size: 13px;
    color: var(--gray);

    &:before,
    &:after {
      height: 2px;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &:before {
      background-color: var(--brdr-gray);
    }

    &:after {
      background-color: var(--primary);
      transform: scaleX(0);
      transition: transform .2s;
      width: 100%;
    }

    &:hover, &.current {
      &:after {
        transform: scaleX(1);
      }
    }

    &:hover {
      color: var(--black);
    }

    &.current {
      color: var(--primary);
    }

  }

  &.full {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      border-bottom: 1px solid var(--brdr-gray);
      bottom: 2px;
    }

    .yan-tab-item:before {
      display: none;
    }
  }

}

.yan-big-tabs {
  overflow: hidden;
  display: flex;

  .tab-item {
    width: 100%;
    background: var(--bgd-gray);

    &.checked {
      background: #fff !important;
    }

    .tab-item-content {
      height: 55px;
      border-right: 1px solid var(--brdr-gray);
      border-bottom: 1px solid var(--brdr-gray);;
      border-top: 2px solid transparent;
      cursor: pointer;
      line-height: 55px;
      text-align: center;
      padding: 0 5px;
      font-size: 13px;
      transition: all .2s ease-in;
    }

    &:last-child .tab-item-content {
      border-right: 0px !important;
    }

    &:hover .tab-item-content {
      border-top-color: #8a939e;
    }

    &.checked .tab-item-content {
      border-bottom: 0px !important;
      border-top-color: var(--primary);
    }
  }
}

#app-direction[dir="rtl"] {
  .yan-big-tabs {
    .tab-item-content {
      border-right: unset;
      border-left: 1px solid var(--brdr-gray);
      font-size: 13px;
    }

    &:last-child .tab-item-content {
      border-left: 0px !important;
    }
  }
}

/*** YAN MENU **/
.mat-menu-panel {
  max-width: unset !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 21px;
  border-radius: 5px !important;
}

.yan-menu {
  max-width: 250px;
  min-width: 150px;

  .yan-menu-padding {
    padding: 12px 20px;

    .mat-menu-item {
      border-radius: 5px;
    }
  }

  .yan-menu-ttl {
    font-weight: 450;
    margin-bottom: 10px;
  }

  .mat-menu-item {
    line-height: 40px;
    height: 40px;

    &:hover {
      color: var(--primary);
    }
  }

  .sep {
    border-top: 1px solid var(--brdr-gray);
    margin: 20px 0;
  }

  .yan-menu-content {
    overflow: overly;
    padding-left: 10px;
    color: var(--gray-dark);

    .yan-menu-item {
      margin-bottom: 12px;
    }

  }

  .yan-filters {
    margin-top: 10px;
    overflow: overly;

    .filter-item {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      .fi-header {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        border-radius: 7px;
        cursor: pointer;
        background-color: var(--bgd-gray);

        .ttl {
          color: var(--gray-dark);
        }

        .arrow {
          margin-left: auto;
          height: 20px;
          width: 20px;
          text-align: center;
          background: url('/assets/img/right-arrow_.svg') center no-repeat;
          background-size: 12px 12px;
          transition: transform .2s;
          flex-shrink: 0;
          cursor: pointer;
        }

        &:hover .arrow {
          background: url('/assets/img/right-arrow-red.svg') center no-repeat;
          background-size: 12px 12px;
        }
      }


      &.open {
        .arrow {
          background: url('/assets/img/right-arrow-red.svg') center no-repeat;
          background-size: 12px 12px;
          transform: rotate(90deg);
        }
      }

      .fi-content {
        padding: 5px;

        .mat-form-field {
          width: 100%;
        }

        .clear {
          color: var(--gray);
          cursor: pointer;
          display: inline-block;
          font-size: 13px;
          margin-top: 7px;

          &:hover {
            color: var(--red);
          }
        }
      }

    }
  }

  .yan-menu-actions {
    padding: 20px 20px 12px;
    border-top: 1px solid var(--brdr-gray);
    display: flex;
    align-items: center;


    .submit {
      margin-left: auto;
      line-height: 30px;
      background: var(--primary);
    }

    .remove {
      font-weight: 450;
      color: var(--gray-dark);
      font-size: 13px;
      cursor: pointer;

      &:hover {
        color: var(--black);
      }
    }
  }
}

.cdk-overlay-connected-position-bounding-box[dir="rtl"] {
  .yan-menu {

    .yan-menu-content {
      padding-left: unset;
      padding-right: 10px;
    }

    .yan-filters {
      .filter-item {
        .fi-header {
          .arrow {
            margin-right: auto;
            margin-left: unset;
            transform: rotate(180deg);
          }
        }

        &.open {
          .arrow {
          }
        }
      }
    }

    .yan-menu-actions {
      .submit {
        margin-left: unset;
        margin-right: auto;
      }
    }
  }
}

/*** YAN SECTION TITLE **/
.yan-section-title {
  padding-left: 15px;
  position: relative;
  font-weight: 450;
  color: var(--gray);
  font-size: 16px;
  margin-bottom: 15px;

  &:after {
    position: absolute;
    content: '';
    display: block;
    height: 18px;
    width: 3px;
    background: var(--secondary);
    top: 50%;
    margin-top: -10px;
    left: 0;
  }
}

/*** AUTOCOMPLETE ***/
.yan-autocomplete {
  position: relative;


  .yan-autocomplete-panel {
    position: absolute;
    width: 100%;
    background: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 21px;
    border-radius: 10px;
    z-index: 1000;

    .no-item {
      height: 80px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: 450;
      }
    }

    .autocomplete-loader {
      position: relative;
      min-height: 100px;
    }

    .result-items {
      position: relative;
      max-height: 300px;
      overflow: overlay;

      .ri-item {
        cursor: pointer;
        padding: 15px 20px;
        transition: background-color 200ms ease-in;

        &:hover {
          background-color: rgba(1, 142, 245, .1);
        }
      }
    }

    .new-item {
      color: var(--primary);
      cursor: pointer;
      border-top: 1px solid var(--brdr-gray);
      padding: 15px;
      text-align: center;
      border-radius: 0 0 10px 10px;

      &:hover {
        background: var(--bgd-gray);
      }
    }

  }
}

/** YAN AVATAR **/
.yan-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  flex-shrink: 0;

  .default-avatar {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    font-weight: 450;
    background-color: var(--primary);
    line-height: 40px;
  }

  .default-avatar,
  img {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

/*** YAN MODAL **/
.yan-modal {
  position: relative;
  height: 100%;

  .close-modal {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
    background: url("/assets/img/close.svg") center no-repeat;
    background-size: 15px 15px;
    cursor: pointer;
  }

  .yan-modal-title {
    font-size: 18px;
    font-weight: 450;
    border-bottom: 1px solid var(--brdr-gray);
    padding: 18px 20px;
  }

  .yan-modal-filter {
    padding: 10px;
    border-bottom: 1px solid var(--brdr-gray);

    .yan-input {
      line-height: 40px;
      box-shadow: none !important;
    }
  }

  .yan-modal-content {
    padding: 20px;
    overflow: auto;
    position: relative;
    max-height: calc(100vh - 250px);
  }

  .yan-modal-btns {
    padding: 15px;
    display: flex;
    justify-content: right;
    gap: 7px;
    background: var(--bgd-gray);

    .yan-btn {
      line-height: 35px;
      min-width: 100px;
    }
  }

}

.cdk-global-overlay-wrapper[dir="rtl"] {
  .yan-modal .close-modal {
    right: unset;
    left: 10px;
  }
}

/** YAN CHIP **/
.yan-chip-list {
  padding: 15px 10px;
  border-radius: 8px;
  border: 1px solid var(--brdr-gray);
  background: #fff;

  .mat-chip {
    background-color: var(--bgd-gray) !important;
    font-weight: 400;
    border-radius: 6px !important;
  }

  .mat-chip-remove {
    color: var(--red) !important;
    height: unset;
    font-weight: 450;
    font-size: 16px;
    margin-top: -6px;
  }

  input.mat-chip-input {
    border: none;
    font-weight: 400;
  }
}

/**** YAN MORE WRAPPER ****/
.over-div {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1999;
  background: #000;
  opacity: .2;
}

.yan-more-wrapper {
  position: fixed;
  height: calc(100vh - 65px);
  top: 65px;
  right: -100%;
  box-shadow: 0 3px 24px 0 rgb(22 45 61 / 18%), 0 8px 8px 0 rgb(22 45 61 / 12%);
  z-index: 2000;
  transition: right 0.4s ease 0s;
  width: 420px;
  will-change: width;

  &.open {
    right: 0;
  }

  .wrapper-content {
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;

    .wc-header {
      display: flex;
      padding: 20px 15px 15px;
      border-bottom: 1px solid var(--brdr-gray);
      flex-shrink: 0;

      .close {
        margin-left: auto;
        height: 30px;
        width: 30px;
        background: url('/assets/img/close.svg') center no-repeat;
        background-size: 15px 15px;
        cursor: pointer;
      }
    }

    .wc-body {
      overflow: auto;
      flex-grow: 1;
      padding: 15px;
      position: relative;
    }

    .wc-footer {
      margin-top: auto;
      border-top: 1px solid var(--brdr-gray);
      padding: 20px 12px;
      display: flex;

      .submit {
        margin-left: auto;
      }
    }
  }
}

#app-direction[dir="rtl"] {
  .yan-more-wrapper {
    right: unset;
    left: -100%;
    transition: left 0.4s ease 0s;

    &.open {
      right: unset;
      left: 0;
    }

    .wrapper-content {
      .wc-header {
        .close {
          margin-right: auto;
          margin-left: unset;
        }
      }

      .wc-footer {
        .submit {
          margin-left: unset;
          margin-right: auto;
        }
      }
    }
  }
}

.yan-sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: super;
  top: -0.5em;
  font-weight: 450;
}

.yan-status-container {
  display: flex;
  justify-content: center;
}

.yan-status {
  border-radius: 9px;
  padding: 2px 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: rgba(111, 107, 249, 0.1);
  min-width: 120px;
  margin: 3px 0;
  color: var(--primary);

  .ic {
    height: 10px;
    width: 10px;
    background: var(--primary);
    border-radius: 3px;
  }

  span {
    font-size: 13px;
    opacity: inherit;
  }

  &.filled {
    border: unset;
    background: var(--primary);
    color: #fff;
  }
}


#app-direction[dir="rtl"] {
  .yan-status {
    font-size: 13px;
  }
}

.yan-underline {
  position: relative;
  background-image: url('/assets/img/welcome/underline.svg');
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.welcome-section-title {
  font-size: 30px;
  margin-bottom: 70px;
  text-align: center;
  color: var(--primary);
  text-transform: uppercase;
  font-weight: 450;
  @media screen and (max-width: 750px) {
    margin-bottom: 40px;
  }
}

.country-item {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 2px 5px;

  .fi {
    border-radius: 3px;
    background-size: cover;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.currency-item {
  gap: 5px;
  display: flex;
  align-items: center;
}

/*** UPLOADING ****/
.upload-area {
  border: 1px dashed var(--brdr-gray);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  text-align: center;

  &.file-over-class {
    border-color: var(--secondary);
  }

  .ic {
    height: 30px;
    width: 30px;
    background: url('/assets/img/upload.svg') center no-repeat;
    background-size: contain;
  }

  .lbl {
    font-size: 13px;
    margin-top: 5px;
    color: var(--gray);
  }

  .yan-btn {
    line-height: 30px;
    margin-top: 15px;
    background: var(--orange);
  }
}
