:root {
  --primary: #553AD8;
  --primary-dark: #3E2A9B;
  --secondary: #018EF5;
  --secondary-dark: #063efc;

  --red: #ff3067;
  --green: #06d6a0;
  --orange: #FCAC1F;
  --purple: #3865fd;
  --blue: #0881E5;
  --pink: #FF78AF;
  --gray: #647fa1;
  --gray-dark: #2d4156;

  --bgd-gray: #f1f5f9;
  --brdr-gray: rgba(0, 0, 0, .1);
  --black: #000;

  --font-thin: "Roboto-Thin";
  --font-Light: "Roboto-Light";
  --font-Regular: "Roboto-Regular";
  --font-Medium: "Roboto-Medium";
  --font-SemiBold: "Roboto-Medium";
  --font-Bold: "Roboto-Bold";

}


