.cfi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
  margin-right: 10px;
}

.cfi:before {
  content: '\00a0'
}

.cfi-aed {
  background-image: url('/assets/img/flags_svg/aed.svg')
}

.cfi-afn {
  background-image: url('/assets/img/flags_svg/afn.svg')
}

.cfi-all {
  background-image: url('/assets/img/flags_svg/currency.svg')
}

.cfi-amd {
  background-image: url('/assets/img/flags_svg/amd.svg')
}

.cfi-ang {
  background-image: url('/assets/img/flags_svg/ang.svg')
}

.cfi-aoa {
  background-image: url('/assets/img/flags_svg/aoa.svg')
}

.cfi-ars {
  background-image: url('/assets/img/flags_svg/ars.svg')
}

.cfi-aud {
  background-image: url('/assets/img/flags_svg/aud.svg')
}

.cfi-awg {
  background-image: url('/assets/img/flags_svg/awg.svg')
}

.cfi-azn {
  background-image: url('/assets/img/flags_svg/azn.svg')
}

.cfi-bam {
  background-image: url('/assets/img/flags_svg/bam.svg')
}

.cfi-bbd {
  background-image: url('/assets/img/flags_svg/bbd.svg')
}

.cfi-bdt {
  background-image: url('/assets/img/flags_svg/bdt.svg')
}

.cfi-bgn {
  background-image: url('/assets/img/flags_svg/bgn.svg')
}

.cfi-bhd {
  background-image: url('/assets/img/flags_svg/bhd.svg')
}

.cfi-bif {
  background-image: url('/assets/img/flags_svg/bif.svg')
}

.cfi-bmd {
  background-image: url('/assets/img/flags_svg/bmd.svg')
}

.cfi-bnd {
  background-image: url('/assets/img/flags_svg/bnd.svg')
}

.cfi-bob {
  background-image: url('/assets/img/flags_svg/bob.svg')
}

.cfi-brl {
  background-image: url('/assets/img/flags_svg/brl.svg')
}

.cfi-bsd {
  background-image: url('/assets/img/flags_svg/bsd.svg')
}

.cfi-btn {
  background-image: url('/assets/img/flags_svg/btn.svg')
}

.cfi-bwp {
  background-image: url('/assets/img/flags_svg/bwp.svg')
}

.cfi-byn {
  background-image: url('/assets/img/flags_svg/byn.svg')
}

.cfi-bzd {
  background-image: url('/assets/img/flags_svg/bzd.svg')
}

.cfi-cad {
  background-image: url('/assets/img/flags_svg/cad.svg')
}

.cfi-cdf {
  background-image: url('/assets/img/flags_svg/cdf.svg')
}

.cfi-chf {
  background-image: url('/assets/img/flags_svg/chf.svg')
}

.cfi-clf {
  background-image: url('/assets/img/flags_svg/currency.svg')
}

.cfi-clp {
  background-image: url('/assets/img/flags_svg/clp.svg')
}

.cfi-cny {
  background-image: url('/assets/img/flags_svg/cny.svg')
}

.cfi-cop {
  background-image: url('/assets/img/flags_svg/cop.svg')
}

.cfi-crc {
  background-image: url('/assets/img/flags_svg/crc.svg')
}

.cfi-cuc {
  background-image: url('/assets/img/flags_svg/cuc.svg')
}

.cfi-cup {
  background-image: url('/assets/img/flags_svg/cup.svg')
}

.cfi-cve {
  background-image: url('/assets/img/flags_svg/cve.svg')
}

.cfi-czk {
  background-image: url('/assets/img/flags_svg/czk.svg')
}

.cfi-djf {
  background-image: url('/assets/img/flags_svg/djf.svg')
}

.cfi-dkk {
  background-image: url('/assets/img/flags_svg/dkk.svg')
}

.cfi-dop {
  background-image: url('/assets/img/flags_svg/dop.svg')
}

.cfi-dzd {
  background-image: url('/assets/img/flags_svg/dzd.svg')
}

.cfi-egp {
  background-image: url('/assets/img/flags_svg/egp.svg')
}

.cfi-etb {
  background-image: url('/assets/img/flags_svg/etb.svg')
}

.cfi-ern {
  background-image: url('/assets/img/flags_svg/ern.svg')
}

.cfi-eur {
  background-image: url('/assets/img/flags_svg/eur.svg')
}

.cfi-fjd {
  background-image: url('/assets/img/flags_svg/fjd.svg')
}

.cfi-fkp {
  background-image: url('/assets/img/flags_svg/fkp.svg')
}

.cfi-gbp {
  background-image: url('/assets/img/flags_svg/gbp.svg')
}

.cfi-gel {
  background-image: url('/assets/img/flags_svg/gel.svg')
}

.cfi-ghs {
  background-image: url('/assets/img/flags_svg/ghs.svg')
}

.cfi-gip {
  background-image: url('/assets/img/flags_svg/gip.svg')
}

.cfi-gmd {
  background-image: url('/assets/img/flags_svg/gmd.svg')
}

.cfi-gnf {
  background-image: url('/assets/img/flags_svg/gnf.svg')
}

.cfi-gtq {
  background-image: url('/assets/img/flags_svg/gtq.svg')
}

.cfi-gyd {
  background-image: url('/assets/img/flags_svg/gyd.svg')
}

.cfi-hkd {
  background-image: url('/assets/img/flags_svg/hkd.svg')
}

.cfi-hnl {
  background-image: url('/assets/img/flags_svg/hnl.svg')
}

.cfi-htg {
  background-image: url('/assets/img/flags_svg/htg.svg')
}

.cfi-huf {
  background-image: url('/assets/img/flags_svg/huf.svg')
}

.cfi-idr {
  background-image: url('/assets/img/flags_svg/idr.svg')
}

.cfi-ils {
  background-image: url('/assets/img/flags_svg/ils.svg')
}

.cfi-inr {
  background-image: url('/assets/img/flags_svg/inr.svg')
}

.cfi-iqd {
  background-image: url('/assets/img/flags_svg/iqd.svg')
}

.cfi-irr {
  background-image: url('/assets/img/flags_svg/irr.svg')
}

.cfi-isk {
  background-image: url('/assets/img/flags_svg/isk.svg')
}

.cfi-jmd {
  background-image: url('/assets/img/flags_svg/jmd.svg')
}

.cfi-jod {
  background-image: url('/assets/img/flags_svg/jod.svg')
}

.cfi-jpy {
  background-image: url('/assets/img/flags_svg/jpy.svg')
}

.cfi-kes {
  background-image: url('/assets/img/flags_svg/kes.svg')
}

.cfi-kgs {
  background-image: url('/assets/img/flags_svg/kgs.svg')
}

.cfi-khr {
  background-image: url('/assets/img/flags_svg/khr.svg')
}

.cfi-kmf {
  background-image: url('/assets/img/flags_svg/kmf.svg')
}

.cfi-kpw {
  background-image: url('/assets/img/flags_svg/kpw.svg')
}

.cfi-krw {
  background-image: url('/assets/img/flags_svg/krw.svg')
}

.cfi-kwd {
  background-image: url('/assets/img/flags_svg/kwd.svg')
}

.cfi-kyd {
  background-image: url('/assets/img/flags_svg/kyd.svg')
}

.cfi-kzt {
  background-image: url('/assets/img/flags_svg/kzt.svg')
}

.cfi-lak {
  background-image: url('/assets/img/flags_svg/lak.svg')
}

.cfi-lbp {
  background-image: url('/assets/img/flags_svg/lbp.svg')
}

.cfi-lkr {
  background-image: url('/assets/img/flags_svg/lkr.svg')
}

.cfi-lrd {
  background-image: url('/assets/img/flags_svg/lrd.svg')
}

.cfi-lsl {
  background-image: url('/assets/img/flags_svg/lsl.svg')
}

.cfi-lyd {
  background-image: url('/assets/img/flags_svg/lyd.svg')
}

.cfi-mad {
  background-image: url('/assets/img/flags_svg/mad.svg')
}

.cfi-mdl {
  background-image: url('/assets/img/flags_svg/mdl.svg')
}

.cfi-mga {
  background-image: url('/assets/img/flags_svg/mga.svg')
}

.cfi-mkd {
  background-image: url('/assets/img/flags_svg/mkd.svg')
}

.cfi-mmk {
  background-image: url('/assets/img/flags_svg/mmk.svg')
}

.cfi-mnt {
  background-image: url('/assets/img/flags_svg/mnt.svg')
}

.cfi-mop {
  background-image: url('/assets/img/flags_svg/mop.svg')
}

.cfi-mur {
  background-image: url('/assets/img/flags_svg/mur.svg')
}

.cfi-mvr {
  background-image: url('/assets/img/flags_svg/mvr.svg')
}

.cfi-mwk {
  background-image: url('/assets/img/flags_svg/mwk.svg')
}

.cfi-mxn {
  background-image: url('/assets/img/flags_svg/mxn.svg')
}

.cfi-myr {
  background-image: url('/assets/img/flags_svg/myr.svg')
}

.cfi-mzn {
  background-image: url('/assets/img/flags_svg/mzn.svg')
}

.cfi-nad {
  background-image: url('/assets/img/flags_svg/nad.svg')
}

.cfi-ngn {
  background-image: url('/assets/img/flags_svg/ngn.svg')
}

.cfi-nio {
  background-image: url('/assets/img/flags_svg/nio.svg')
}

.cfi-nok {
  background-image: url('/assets/img/flags_svg/nok.svg')
}

.cfi-npr {
  background-image: url('/assets/img/flags_svg/npr.svg')
}

.cfi-nzd {
  background-image: url('/assets/img/flags_svg/nzd.svg')
}

.cfi-omr {
  background-image: url('/assets/img/flags_svg/omr.svg')
}

.cfi-pab {
  background-image: url('/assets/img/flags_svg/pab.svg')
}

.cfi-pen {
  background-image: url('/assets/img/flags_svg/pen.svg')
}

.cfi-php {
  background-image: url('/assets/img/flags_svg/php.svg')
}

.cfi-pkr {
  background-image: url('/assets/img/flags_svg/pkr.svg')
}

.cfi-pln {
  background-image: url('/assets/img/flags_svg/pln.svg')
}

.cfi-pyg {
  background-image: url('/assets/img/flags_svg/pyg.svg')
}

.cfi-qar {
  background-image: url('/assets/img/flags_svg/qar.svg')
}

.cfi-ron {
  background-image: url('/assets/img/flags_svg/ron.svg')
}

.cfi-rsd {
  background-image: url('/assets/img/flags_svg/rsd.svg')
}

.cfi-rub {
  background-image: url('/assets/img/flags_svg/rub.svg')
}

.cfi-rwf {
  background-image: url('/assets/img/flags_svg/rwf.svg')
}

.cfi-sar {
  background-image: url('/assets/img/flags_svg/sar.svg')
}

.cfi-sbd {
  background-image: url('/assets/img/flags_svg/sbd.svg')
}

.cfi-scr {
  background-image: url('/assets/img/flags_svg/scr.svg')
}

.cfi-sdg {
  background-image: url('/assets/img/flags_svg/sdg.svg')
}

.cfi-sek {
  background-image: url('/assets/img/flags_svg/sek.svg')
}

.cfi-sgd {
  background-image: url('/assets/img/flags_svg/sgd.svg')
}

.cfi-shp {
  background-image: url('/assets/img/flags_svg/shp.svg')
}

.cfi-sll {
  background-image: url('/assets/img/flags_svg/sll.svg')
}

.cfi-sos {
  background-image: url('/assets/img/flags_svg/sos.svg')
}

.cfi-srd {
  background-image: url('/assets/img/flags_svg/srd.svg')
}

.cfi-ssp {
  background-image: url('/assets/img/flags_svg/currency.svg')
}

.cfi-syp {
  background-image: url('/assets/img/flags_svg/syp.svg')
}

.cfi-szl {
  background-image: url('/assets/img/flags_svg/szl.svg')
}

.cfi-thb {
  background-image: url('/assets/img/flags_svg/thb.svg')
}

.cfi-tjs {
  background-image: url('/assets/img/flags_svg/tjs.svg')
}

.cfi-tmt {
  background-image: url('/assets/img/flags_svg/tmt.svg')
}

.cfi-tnd {
  background-image: url('/assets/img/flags_svg/tnd.svg')
}

.cfi-top {
  background-image: url('/assets/img/flags_svg/top.svg')
}

.cfi-try {
  background-image: url('/assets/img/flags_svg/try.svg')
}

.cfi-ttd {
  background-image: url('/assets/img/flags_svg/ttd.svg')
}

.cfi-twd {
  background-image: url('/assets/img/flags_svg/twd.svg')
}

.cfi-tzs {
  background-image: url('/assets/img/flags_svg/tzs.svg')
}

.cfi-uah {
  background-image: url('/assets/img/flags_svg/uah.svg')
}

.cfi-ugx {
  background-image: url('/assets/img/flags_svg/ugx.svg')
}

.cfi-usd {
  background-image: url('/assets/img/flags_svg/usd.svg')
}

.cfi-uyu {
  background-image: url('/assets/img/flags_svg/uyu.svg')
}

.cfi-uzs {
  background-image: url('/assets/img/flags_svg/uzs.svg')
}

.cfi-vnd {
  background-image: url('/assets/img/flags_svg/vnd.svg')
}

.cfi-vuv {
  background-image: url('/assets/img/flags_svg/vuv.svg')
}

.cfi-wst {
  background-image: url('/assets/img/flags_svg/wst.svg')
}

.cfi-xaf {
  background-image: url('/assets/img/flags_svg/xaf.svg')
}

.cfi-xcd {
  background-image: url('/assets/img/flags_svg/xcd.svg')
}

.cfi-xof {
  background-image: url('/assets/img/flags_svg/xof.svg')
}

.cfi-xpf {
  background-image: url('/assets/img/flags_svg/xpf.svg')
}

.cfi-yer {
  background-image: url('/assets/img/flags_svg/yer.svg')
}

.cfi-zar {
  background-image: url('/assets/img/flags_svg/zar.svg')
}

.cfi-zmw {
  background-image: url('/assets/img/flags_svg/zmw.svg')
}

.cfi-zwl {
  background-image: url('/assets/img/flags_svg/zwl.svg')
}

.cfi-mru {
  background-image: url('/assets/img/flags_svg/mru.svg')
}
