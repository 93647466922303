@import 'bootstrap/scss/bootstrap';
@import "./assets/scss/tuesday.min.css";
@import 'node_modules/ngx-bar-rating/themes/br-default-theme.scss';
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'node_modules/froala-editor/css/froala_editor.pkgd.min';
@import 'node_modules/froala-editor/css/froala_style.min';
@import "variables";
@import "global";
@import "common";
@import "yan";
@import "curr-flags";

