* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  position: relative;
  font-family: "Inter", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--black);
  background-color: #fff;
}

#app-direction[dir="rtl"] {
  body {
    font-size: 16px;
  }
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--black);

  &:hover {
    color: var(--primary);
  }
}

//
//p {
//  color: #506580;
//  text-align: justify;
//  margin-bottom: 0;
//}

.upper {
  text-transform: uppercase;
}

.left {
  float: left;
}

.right {
  float: right;
}

.overflow {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

button {
  outline: none !important;
}

::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border: 5px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}
