/* COMMON CLASSES & COMPONENTS */

/*** LOADING BAR SPINNER **/
ngx-loading-bar {
  z-index: 100002 !important;
}


.ngx-spinner-overlay {
  z-index: 100002 !important;
}

/** MODAL **/
.cdk-overlay-container {
  z-index: 100100;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 5px !important;
  overflow: hidden !important;
}

/** MAT SLIDER **/
.mat-slide-toggle-bar {
  background: #ccc;
}

/** TEXT TRUNCATE */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** RATING **/
.br-unit {
  font-size: 14px !important;
}

.br-default .br-active:before,
.br-default .br-fraction:after {
  color: var(--red) !important;
}

/*** MAT CHECKBOX / RADIO BOX**/
.mat-radio-outer-circle,
.mat-checkbox-frame {
  border-width: 1px !important;
}

.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
  margin-right: 12px !important;
  background: #fff;
  border-radius: 3px;
}

.mat-radio-outer-circle {
  background: #fff;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}


/** MAT  Multi select **/
.mat-pseudo-checkbox {
  border-width: 1px !important;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--red);
}

.mat-pseudo-checkbox-checked::after {
  top: 3px !important;
  left: 2px !important;
}


/** TOASTR ***/
.toast-container {
  z-index: 100100;
  position: fixed !important;
}

/*** MAT DATE **/
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-infix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  .mat-button-wrapper svg {
    background: url("/assets/img/calendar.svg") center no-repeat;
    background-size: 18px 18px;

    path {
      display: none;
    }
  }
}

.mat-form-field-infix {
  display: flex !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.mat-calendar-body-selected {
  background-color: var(--green) !important;
}

.mat-datepicker-dialog {
  overflow: scroll;
}

/*** NG SELECT (AUTOCOMPLETE) ***/
.ng-select .ng-select-container {
  color: var(--black);
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: border-color 0.5s ease;
  min-height: 47px;
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-radius: 8px !important;
}

.ng-dropdown-panel {
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 21px;
  border: 0;
  border-radius: 8px !important;
  z-index: 100200 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 10px;
  transition: all 200ms ease-in;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: var(--red);
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-radius: 3px !important;
}


/*** FROALA EDITOR **/
.fr-box.fr-basic {
  .fr-sticky-on {
    position: sticky !important;
  }

  .fr-element {
    min-height: 140px;
  }

  .fr-second-toolbar {
    #fr-logo {
      display: none;
    }
  }

  &.ng-invalid.ng-touched {
    & > div {
      border-color: var(--red);
    }
  }
}

.fr-view {
  p {
    margin-bottom: 0;
  }
}

.fr-sticky-dummy {
  display: none !important;
}

.fr-fullscreen-wrapper {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: #ffffff;
  overflow: hidden;

  .yan-page.yan-form {
    max-width: unset;
  }
}

/*** GALLERY ***/
gallery-core {
  background: #fff !important;
}

gallery-thumb {
  padding: 2px 0 2px 2px !important;
  height: 70px !important;
}

gallery-counter {
  display: none;
}

